<app-search-overlay *ngIf="config"
  [config]="config"
  (open)="onOpen()"
  [canEdit]="canEdit"
  (change)="onChange($event); change.emit($event)">
</app-search-overlay>

<ng-template #selectedTemplate>
  <div class="d-flex align-items-center" *ngFor="let user of config.selecteds">
    <app-avatar [url]="user.avatar" [name]="user.fullName">
    </app-avatar>
    <div *ngIf="showFullname" class="ml-2 text-dark text-ellipsis"> {{ user.fullName }} </div>
  </div>
  <div class="d-flex align-items-center" *ngIf="config.selecteds.length == 0">
    <app-avatar></app-avatar>
    <div *ngIf="showFullname" class="ml-2 text-dark text-ellipsis">{{'Unassigned' | translate}}</div>
  </div>
</ng-template>

<ng-template #optionTemplate let-item="item">
  <app-avatar [url]="item.avatar" [name]="item.fullName"></app-avatar>
  <span class="option-label">{{item.fullName}}</span>
</ng-template>
