import { Component, Input } from '@angular/core';
import { Team } from 'src/app/shared/_models';
import { FIRST_PAGE, OPTIONS_PER_PAGE } from 'src/app/shared/_utils';
import { TeamService } from 'src/app/site-management/team/_services/team.service';
import { SearchOverlayBaseComponent } from '../search-overlay-base.component';
import { stringify } from 'query-string';

@Component({
  selector: 'app-search-overlay-team',
  templateUrl: './search-overlay-team.component.html',
  styleUrls: ['./search-overlay-team.component.scss']
})
export class SearchOverlayTeamComponent extends SearchOverlayBaseComponent<Team> {
  @Input() displayLabels = 1;

  constructor(
    private teamService: TeamService,
  ) {
    super();
    this.config.title = 'Team';
    this.config.multiple = true;
  }

  getSearchFn() {
    return (keyword?: string) => {
      this.config.searchSubscription?.unsubscribe();
      this.config.loading = true;
      const query = stringify({
        page: FIRST_PAGE,
        size: OPTIONS_PER_PAGE,
        name: keyword,
      });
      this.config.searchSubscription = this.teamService.getWithQuery(query).subscribe(
        (res) => {
          this.config.items = res;
          this.config.loading = false;
          this.config.triggerItems$.next(res);
        },
        (error) => {
          this.config.items = [];
          this.config.loading = false;
          this.config.triggerItems$.next([]);
        }
      );
    };
  }

  getTeamLabels() {
    return this.config.selecteds.map(e => e.name);
  }
}
