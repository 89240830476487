import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { UtilService } from 'src/app/core';
import {
  AbstractComponent,
  BoardEpic, BoardLabel, BoardRelease, BoardReleaseListResponse, BoardSprint, DATE_UI_FORMAT,
  Field,
  Metrics,
  Project,
  ProjectMemberUser, ProjectPlatform, ProjectPriority, ProjectType, Task, TaskDetailFormType, TaskPatch, TaskStorylineEstimate,
  TaskType, TaskTypeEnum, User
} from 'src/app/shared';
import { FieldDataChange } from 'src/app/shared/_components/dynamic-field/d-field/d-field.component';
import { BoardStoryline } from 'src/app/shared/_models/board-storyline.model';
import { GeneralConfirmComponent } from 'src/app/site-management/_components';
import { LicenseDataService } from 'src/app/site-management/_services/license-data.service';
import { getMembersByProject } from 'src/app/site-management/_store/project';
import { FieldService } from 'src/app/site-management/dynamic-field/_services/field-service';
import { MetricService } from 'src/app/site-management/metric/_services/metric.service';
import {
  ProjectBoardEpicService, ProjectBoardLabelService, ProjectBoardReleaseService, ProjectBoardSprintService,
  ProjectPriorityService, ProjectsService, ProjectTaskTypeService
} from 'src/app/site-management/projects/_services';
import { ProjectBoardStorylineService } from 'src/app/site-management/projects/_services/project-board-storyline.service';
import { ProjectsMasterDataService } from 'src/app/site-management/projects/_services/projects-master-data.service';
import { TaskService } from '../../_services/task.service';
import { TaskTemplateUpsertComponent } from '../task-template-upsert/task-template-upsert.component';

export enum FormControlLabel {
  StartTime = 'startTime',
  EndTime = 'endTime'
}

@Component({
  selector: 'app-task-detail-form',
  templateUrl: './task-detail-form.component.html',
  styleUrls: ['./task-detail-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TaskDetailFormComponent extends AbstractComponent implements OnInit, OnDestroy {
  @Input() task: Task;
  @Input() form: UntypedFormGroup;
  @Input() fields: Field[] = [];
  @Output() patchValue = new EventEmitter<TaskPatch>();
  @Output() openTask = new EventEmitter<Task>();

  taskRes: Task;
  needRefreshPlatforms = false;
  assignees: ProjectMemberUser[] = [];
  reporters: ProjectMemberUser[] = [];
  ccs: ProjectMemberUser[] = [];
  types: TaskType[] = [];
  priorities: ProjectPriority[] = [];
  epics: BoardEpic[] = [];
  storylines: BoardStoryline[] = [];
  labels: BoardLabel[] = [];
  versions: BoardReleaseListResponse[] = [];
  sprints: BoardSprint[] = [];
  metrics: Metrics[] = [];
  platforms: ProjectPlatform[] = [];
  availablePlatforms: ProjectPlatform[] = [];
  TaskTypeEnum = TaskTypeEnum;
  TaskDetailFormType = TaskDetailFormType;

  assigneeTypeahead$ = new Subject<string>();
  reporterTypeahead$ = new Subject<string>();
  ccTypeahead$ = new Subject<string>();
  epicTypeahead$ = new Subject<string>();
  storylineTypeahead$ = new Subject<string>();
  labelTypeahead$ = new Subject<string>();
  versionTypeahead$ = new Subject<string>();
  sprintTypeahead$ = new Subject<string>();
  metricTypeahead$ = new Subject<string>();
  destroyed$ = new Subject<void>();
  assigneeSubscription: Subscription;
  reporterSubscription: Subscription;
  ccSubscription: Subscription;
  epicSubscription: Subscription;
  storylineSubscription: Subscription;
  labelSubscription: Subscription;
  versionSubscription: Subscription;
  sprintSubscription: Subscription;
  metricSubscription: Subscription;
  storylineTaskSubscription: Subscription;
  bsConfig: Partial<BsDatepickerConfig> = { dateInputFormat: DATE_UI_FORMAT, customTodayClass: 'bs-datepicker-today' };
  currentProject: Project;

  loading = {
    assignee: true,
    reporter: true,
    cc: true,
    type: true,
    priority: true,
    epic: true,
    platform: true,
    label: true,
    release: true,
    sprint: true,
    metric: true,
    storyline: true,
    version: true,
  };

  formControlLabel = FormControlLabel;

  constructor(
    public projectsService: ProjectsService,
    public projectsMasterDataService: ProjectsMasterDataService,
    public projectTaskTypeService: ProjectTaskTypeService,
    public projectPriorityService: ProjectPriorityService,
    public projectBoardEpicService: ProjectBoardEpicService,
    public projectBoardLabelService: ProjectBoardLabelService,
    public projectBoardReleaseService: ProjectBoardReleaseService,
    public projectBoardSprintService: ProjectBoardSprintService,
    public projectBoardStorylineService: ProjectBoardStorylineService,
    private taskService: TaskService,
    private licenseDataService: LicenseDataService,
    private metricService: MetricService,
    private fieldService: FieldService,
    private modalService: BsModalService,
    private _store: Store,
  ) {
    super();
  }

  ngOnInit(): void {
    this.registerEvents();
    this.currentProject = this.projectsService.getCurrentProject()
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();

    const subscriptions: Subscription[] = [
      this.assigneeSubscription,
      this.reporterSubscription,
      this.ccSubscription,
      this.labelSubscription,
      this.versionSubscription,
      this.sprintSubscription,
      this.storylineSubscription,
      this.storylineTaskSubscription,
      this.metricSubscription,
    ];

    subscriptions.forEach(e => e?.unsubscribe());
  }

  registerEvents() {
    this.observeFormEvent();

    this.assigneeTypeahead$
      .pipe(distinctUntilChanged(), debounceTime(500), takeUntil(this.destroyed$))
      .subscribe(
        (value) => {
          this.searchUsers(value || '', (users) => {
            this.loading.assignee = false;
            this.assignees = users;
          });
        });

    this.reporterTypeahead$
      .pipe(distinctUntilChanged(), debounceTime(500), takeUntil(this.destroyed$))
      .subscribe(
        (value) => {
          this.searchUsers(value || '', (users) => {
            this.loading.reporter = false;
            this.reporters = users;
          });
        });

    this.ccTypeahead$
      .pipe(distinctUntilChanged(), debounceTime(500), takeUntil(this.destroyed$))
      .subscribe(
        (value) => {
          this.searchUsers(value || '', (users) => {
            this.loading.cc = false;
            this.ccs = users;
          });
        });

    this.epicTypeahead$
      .pipe(distinctUntilChanged(), debounceTime(500), takeUntil(this.destroyed$))
      .subscribe(
        (value) => {
          this.getEpics(value || '');
        });

    this.labelTypeahead$
      .pipe(distinctUntilChanged(), debounceTime(500), takeUntil(this.destroyed$))
      .subscribe(
        (value) => {
          this.getLabels(value || '');
        });

    this.versionTypeahead$
      .pipe(distinctUntilChanged(), debounceTime(500), takeUntil(this.destroyed$))
      .subscribe(
        (value) => {
          this.getVersions(value || '');
        });

    this.sprintTypeahead$
      .pipe(distinctUntilChanged(), debounceTime(500), takeUntil(this.destroyed$))
      .subscribe(
        (value) => {
          this.getSprints(value || '');
        });

    this.storylineTypeahead$
      .pipe(distinctUntilChanged(), debounceTime(500), takeUntil(this.destroyed$))
      .subscribe(
        (value) => {
          this.getStorylines(value || '');
        });

    this.form.get('taskRes').valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (task: Task) => {
          if (!task) { return; }
          if (this.needRefreshPlatforms) {
            this.needRefreshPlatforms = false;
            this.getStorylines();
            // this.checkIgnoredsPlatformIds();
          }
        }
      );

    this.getAllPlatforms();
  }

  getUser() {
    this.searchUsers('', (users) => {
      this.assignees = users;
      this.reporters = users;
      this.ccs = users;

      this.loading.assignee = false;
      this.loading.reporter = false;
      this.loading.cc = false;
    });
  }

  observeFormEvent() {
    this.form.get('dueDate').valueChanges.subscribe((value) => {
      const dueDate = this.calculateTaskDate(value, false);
      this.onDateChange(dueDate, 'dueDate');
    });

    this.form.get('startDate').valueChanges.subscribe((value) => {
      const startDate = this.calculateTaskDate(value);
      this.onDateChange(startDate, 'startDate');
    });

    this.form.get('endDate').valueChanges.subscribe((value) => {
      const endDate = this.calculateTaskDate(value, false);
      this.onDateChange(endDate, 'endDate');
    });

    this.form.get('startTime').valueChanges.subscribe((value) => {
      this.onTimeChange(value, 'startTime');
    });

    this.form.get('endTime').valueChanges.subscribe((value) => {
      this.onTimeChange(value, 'endTime');
    });

    this.form.get('assignee').valueChanges.subscribe((value) => {
      this.onAssigneeChange(value);
    });

    this.form.get('reporter').valueChanges.subscribe((value) => {
      this.onReporterChange(value);
    });

    this.form.get('ccs').valueChanges.subscribe((value) => {
      this.onCCsChange(value);
    });

    this.form.get('type').valueChanges.subscribe((value) => {
      const prevValue = this.form.value.type;
      this.onChangeTaskType(value, prevValue);
    });

    this.form.get('priority').valueChanges.subscribe((value) => {
      this.onPriorityChange(value);
    });

    this.form.get('epic').valueChanges.subscribe((value) => {
      this.onEpicChange(value);
    });

    this.form.get('colorCode').valueChanges.subscribe((value) => {
      this.onColorCodeChange(value);
    });

    // this.form.get('timeOriginalEstimate').valueChanges.subscribe((value) => {
    //   this.onEstimateChange(value);
    // });

    // this.form.get('storyPoint').valueChanges.subscribe((value) => {
    //   this.onStoryPointChange(value);
    // });

    this.form.get('labels').valueChanges.subscribe((value) => {
      this.onLabelsChange(value);
    });

    this.form.get('versions').valueChanges.subscribe((value) => {
      this.onVersionsChange(value);
    });

    this.form.get('sprint').valueChanges.subscribe((value) => {
      this.onSprintChange(value);
    });

    this.form.get('privateFlg').valueChanges.subscribe((value) => {
      this.onPrivateChange(value);
    });

    this.form.get('followUp').valueChanges.subscribe((value) => {
      this.onFollowUpChange(value);
    });

    this.form.get('platform').valueChanges.subscribe((value) => {
      this.onPlatformChange(value);
    });

    this.form.get('storyline').valueChanges.subscribe((value) => {
      this.onStorylineChange(value);
    });

    this.form.get('metric').valueChanges.subscribe((value) => {
      this.onMetricChange(value);
    });

    this.form.get('storylineEstimates').valueChanges.subscribe((value) => {
      this.onMapStorylineEstimates();
    });

    this.form.get('taskTemplate').valueChanges.subscribe((value) => {
      this.onTaskTemplateChange(value);
    });
  }

  searchUsers(
    keyword: string = '',
    callback: (user: ProjectMemberUser[]) => void
  ) {
    this._store.dispatch(
      getMembersByProject({
        projectId: this.task.project.id,
        keyword,
        callback: (members) => {
          callback(members);
        }
      })
    );
  }

  isScrumProject() {
    return this.task?.project?.type === ProjectType.SCRUM;
  }

  isStoryline() {
    return this.task?.type?.name === TaskTypeEnum.Storyline;
  }

  clearablePlatform() {
    return !this.form.get('storyline').value;
  }

  isKanbanLicense() {
    return this.licenseDataService.isKanbanLicense();
  }

  canSetPlatform() {
    return !(this.task?.type?.name === TaskTypeEnum.Storyline || this.task?.type?.name === TaskTypeEnum.Epic);
  }

  getTypes() {
    this.loading.type = false;
    this.projectTaskTypeService.get(this.task.project.id, { page: 0, size: 1000 })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          const content = (res.content || []).filter(e => e.name !== TaskTypeEnum.Storyline && e.name !== TaskTypeEnum.TaskTemplate);
          if (this.task?.type?.name === TaskTypeEnum.SubTask) {
            this.types = content;
          } else {
            this.types = content.filter(e => e.name !== TaskTypeEnum.SubTask);
          }
        },
        (error) => {
          this.types = [];
        }
      );
  }

  getPriorities() {
    this.loading.priority = false;
    this.projectPriorityService.get(this.task.project.id, { page: 0, size: 1000 })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          this.priorities = res.content;
        },
        (error) => {
          this.priorities = [];
        }
      );
  }

  getEpics(keyword: string = '') {
    this.loading.epic = false;
    this.epicSubscription?.unsubscribe();
    this.epicSubscription = this.projectBoardEpicService.getListMaster({ projectId: this.task.project.id, keyword }, { page: 0, size: 10 })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          this.epics = res;
        },
        (error) => {
          this.epics = [];
        }
      );
  }

  getStorylines(keyword: string = '') {
    this.loading.storyline = true;
    this.storylines = [];

    this.storylineSubscription?.unsubscribe();
    const currentPlatform: ProjectPlatform = this.form.get('platform').value;
    const availablePlatforms = currentPlatform ? [currentPlatform.id] : [];
    this.storylineSubscription = this.projectBoardStorylineService.searchByAvailablePlatforms(
      this.task.project.id, { keyword, availablePlatforms }, { page: 0, size: 50 })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          this.storylines = res.content.filter(e => e);
          this.loading.storyline = false;
          // this.getAvailablePlatforms();
        },
        (error) => {
          this.storylines = [];
          this.loading.storyline = false;
          // this.getAvailablePlatforms();
        }
      );
  }

  getAvailablePlatforms() {
    const storylineId: number = this.form.get('storyline').value?.id || this.task.storylineId;
    this.loading.platform = true;
    this.availablePlatforms = [];
    if (storylineId) {
      this.storylineTaskSubscription?.unsubscribe();
      this.storylineTaskSubscription = this.projectBoardStorylineService.getTasks(
        this.task.project.id, storylineId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (res) => {
            const ignoredsPlatformIds = res.map(e => e.platform?.id);
            this.availablePlatforms = this.platforms.filter(platform => ignoredsPlatformIds.findIndex(e => e === platform.id) === -1);
            this.loading.platform = false;
          },
          (error) => {
            this.availablePlatforms = this.platforms;
            this.loading.platform = false;
          }
        );
    } else {
      this.availablePlatforms = this.platforms;
      this.loading.platform = false;
    }
  }

  getLabels(keyword: string = '') {
    this.loading.label = false;
    this.labelSubscription?.unsubscribe();
    this.labelSubscription = this.projectBoardLabelService.get({ projectId: this.task.project.id, keyword }, { page: 0, size: 10 })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          this.labels = res.content;
        },
        (error) => {
          this.labels = [];
        }
      );
  }

  getVersions(keyword: string = '') {
    this.loading.version = false;
    this.versionSubscription?.unsubscribe();
    this.versionSubscription = this.projectBoardReleaseService.get({ projectId: this.task.project.id, keyword }, { page: 0, size: 10, sort: 'createdAt,desc' })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          this.versions = res.content;
        },
        (error) => {
          this.versions = [];
        }
      );
  }

  getSprints(keyword: string = '') {
    this.loading.sprint = false;
    this.sprintSubscription?.unsubscribe();
    const payload = { projectId: this.task.project.id, keyword };
    const pageable = { page: 0, size: 10, sort: 'name,asc' };
    this.sprintSubscription = this.projectBoardSprintService.get(payload, pageable)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          this.sprints = res.content;
        },
        (error) => {
          this.sprints = [];
        }
      );
  }

  getMetrics() {
    this.loading.metric = false;
    this.metricSubscription?.unsubscribe();
    this.metricSubscription = this.metricService.search({ page: 0, size: 1000 })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          this.metrics = res.content;
        },
        (error) => {
          this.metrics = [];
        }
      );
  }

  getAllPlatforms() {
    this.loading.platform = false;
    this.projectsMasterDataService.getPlatFormsByProject(this.task.project.id)
      .subscribe(
        (platforms) => {
          this.platforms = platforms;
          this.onMapStorylineEstimates();
        },
        (error: string) => {
          this.platforms = [];
        }
      );
  }

  canShowEpicField() {
    return this.task && ![TaskTypeEnum.SubTask, TaskTypeEnum.Epic]?.some(e => e === this.task.type?.name) && this.visibleSystemField('EPIC_LINK');
  }

  onMapStorylineEstimates() {
    const storylineEstimates: TaskStorylineEstimate[] = this.form.get('storylineEstimates').value || [];
    const mappedValue: TaskStorylineEstimate[] = this.platforms.map(platform => {
      const taskEt = storylineEstimates.find(s => s.platform?.id === platform.id);
      return taskEt ? taskEt : {
        id: null,
        value: 0,
        platform,
      };
    });
    this.form.get('storylineEstimates').setValue(mappedValue, { emitEvent: false });
  }


  onCreateTemplate() {
    const _modal = this.modalService.show(TaskTemplateUpsertComponent, { backdrop: 'static', initialState: {
      task: this.task,
    }});

    _modal.content.objectResponse.subscribe((template) => {
      if (template) {
        this.form.get('template').setValue(template);
      }
      _modal.hide();
    });
  }

  unlinkTaskTemplate() {
    const initialState = {
      title: 'Unlink Task Template',
      message: 'Do you want to unlink this task template?',
    };
    const confirmModalRef = this.modalService.show(GeneralConfirmComponent, { backdrop: 'static', initialState });

    confirmModalRef.content.result$.subscribe((result) => {
      if (result) {
        this.form.get('template').setValue(null);
        this.patchValue.emit({
          type: 'unlinkTemplate',
          value: 'true',
        });
      }
    });
  }

  onOpenTask(task: Task) {
    this.openTask.emit(task);
  }

  onDateChange(value: string, type: string) {
    this.patchValue.emit({ type, value });
  }

  onTimeChange(time: string, type: string) {
    this.patchValue.emit({
      type,
      value: time ? UtilService.convert12HoursToFullTime(time) : null,
    });
  }

  onAssigneeChange(user: User) {
    this.patchValue.emit({
      type: 'assigneeId',
      value: user ? `${user.id}` : null,
    });
  }

  onReporterChange(user: User) {
    this.patchValue.emit({
      type: 'reporterId',
      value: user ? `${user.id}` : null,
    });
  }

  onCCsChange(users: User[] = []) {
    this.patchValue.emit({
      type: 'ccIds',
      value: `[${users.map(e => e.id)}]`,
    });
  }

  onTypeChange(type: TaskType) {
    this.patchValue.emit({
      type: 'typeId',
      value: type ? `${type.id}` : null,
    });
  }

  onPriorityChange(priority: ProjectPriority) {
    this.patchValue.emit({
      type: 'priorityId',
      value: priority ? `${priority.id}` : null,
    });
  }

  onEpicChange(epic: BoardEpic) {
    this.patchValue.emit({
      type: 'epicId',
      value: epic ? `${epic.id}` : null,
    });
  }

  onColorCodeChange(color: string) {
    this.patchValue.emit({
      type: TaskDetailFormType.ColorCode,
      value: color
    });
  }

  onLabelsChange(labels: BoardLabel[] = []) {
    this.patchValue.emit({
      type: 'labelIds',
      value: `[${labels.map(e => e.id)}]`,
    });
  }

  onVersionsChange(versions: BoardRelease[] = []) {
    this.patchValue.emit({
      type: 'versionIds',
      value: `[${versions.map(e => e.id)}]`,
    });
  }

  onSprintChange(sprint: BoardSprint) {
    this.patchValue.emit({
      type: 'sprintId',
      value: sprint ? `${sprint.id}` : null,
    });
  }

  onEstimateChange() {
    const value = this.form.get('timeOriginalEstimate').value || null;
    this.patchValue.emit({
      type: 'timeOriginalEstimate',
      value: value ? `${value}` : null,
    });
  }

  onProgressChange() {
    const value = this.form.get('progress').value || 0;
    this.patchValue.emit({
      type: 'progress',
      value: value ? `${value}` : 0,
    });
  }

  onStoryPointChange() {
    const value = this.form.get('storyPoint').value || null;
    this.patchValue.emit({
      type: 'storyPoint',
      value: value ? `${value}` : null,
    });
  }

  onPrivateChange(privateFlg: boolean = false) {
    this.patchValue.emit({
      type: 'privateFlg',
      value: `${privateFlg}`,
    });
  }

  onFollowUpChange(followUp: boolean = false) {
    this.patchValue.emit({
      type: 'followUp',
      value: `${followUp}`,
    });
  }

  onTaskTemplateChange(isTaskTemplate: boolean = false) {
    this.patchValue.emit({
      type: TaskDetailFormType.TaskTemplate,
      value: `${isTaskTemplate}`
    });
  }

  onPlatformChange(platform: ProjectPlatform) {
    this.needRefreshPlatforms = true;
    this.patchValue.emit({
      type: 'platformId',
      value: platform ? `${platform.id}` : null,
    });
  }

  onStorylineChange(storyline: BoardStoryline) {
    this.needRefreshPlatforms = true;
    this.patchValue.emit({
      type: 'storylineId',
      value: storyline ? `${storyline.id}` : null,
    });
  }

  onMetricChange(metric: Metrics) {
    this.patchValue.emit({
      type: 'metricId',
      value: metric ? `${metric.id}` : null,
    });
  }

  onStartMetricValueChange() {
    const value = this.form.get('startMetricValue').value || 0;
    this.patchValue.emit({
      type: 'startMetricValue',
      value: value ? `${value}` : 0,
    });
  }

  onMetricValueChange() {
    const value = this.form.get('metricValue').value || 0;
    this.patchValue.emit({
      type: 'metricValue',
      value: value ? `${value}` : 0,
    });
  }

  onCurrentMetricValueChange() {
    const value = this.form.get('currentMetricValue').value || 0;
    this.patchValue.emit({
      type: 'currentMetricValue',
      value: value ? `${value}` : 0,
    });
  }

  onStorylineEstimatesChange() {
    const value = (this.form.get('storylineEstimates').value || []) as TaskStorylineEstimate[];
    this.patchValue.emit({
      type: 'storylineEstimates',
      value: JSON.stringify(value.map(e => {
        return {
          id: e.id,
          value: e.value,
          platformId: e.platform?.id
        };
      })),
    });
  }

  onChangeTaskType(nextTaskType: TaskType, currentTaskType: TaskType) {
    if (!nextTaskType) {
      return;
    }

    if (currentTaskType.name === TaskTypeEnum.SubTask && currentTaskType.code !== nextTaskType.code) {
      const initialState = {
        title: 'titleChangeSubtaskToOtherTask',
        message: 'messageChangeSubtaskToOtherTask',
        params: { type: nextTaskType.name?.toLowerCase() }
      };

      const ref = this.modalService.show(GeneralConfirmComponent, { backdrop: 'static', initialState });
      ref.content.result$.subscribe((result) => {
        if (result) {
          this.onTypeChange(nextTaskType);
          this.getTypes();
        } else {
          this.form.get('type').setValue(currentTaskType, { emitEvent: true });
        }
        ref.hide();
      });
      return;
    }
    this.onTypeChange(nextTaskType);
  }

  // D Field
  onFieldChange(e: FieldDataChange) {
    const payload = { ...e.payload };
    payload.id = e.field.id;
    payload.groupId = this.task.id;

    this.fieldService.updateFieldValue([payload]).pipe(takeUntil(this.destroyed$)).subscribe(
      (res) => {},
      (error: string) => {
      }
    );
  }

  visibleSystemField(hash: string) {
    return this.fields?.find(e => e.hash === hash)?.isVisible;
  }

  calculateTaskDate(date: string, startOfDate = true) {
    if (!date) {
      return null;
    }

    if (startOfDate) {
      return moment(date).startOf('d').utc().format();
    } else {
      return moment(date).endOf('d').utc().format();
    }
  }

  getEpicLink(key: string, id: string, currentProject: string) {
    return '/task-mgmt/projects/' + currentProject + '/storyline?selectedTask=' + key +'&id=' + id
  }
}
