import { Dictionary } from '@ngrx/entity';
import { forEach, keyBy, reduce } from 'lodash';
import {
  Okr,
  OkrItem,
  OkrKeyResult,
  OkrTimelineTree,
  VirtualOkrItem
} from 'src/app/shared';
import { OkrItemChange, UpdateTrigger } from '../_components/okr-tree/okr-tree';
import {
  getOkrType,
  mapKeyResultToVirtualKeyResult,
  mapModelKeyResult,
  mapModelObjective,
  mapModelOkrTask,
  mapObjective,
  mapObjectiveToVirtualObjective,
  mapOkrTaskToVirtualTask,
  mapOriginItemToVirtualOkr
} from './mapper';
import {
  OKR_ROW_FIRST_LEVEL,
  OkrFormType,
  OkrSelectedItem,
  TimelineTreeItem
} from './okr.model';

export const flatSubItems = (item: VirtualOkrItem): VirtualOkrItem[] => {
  return reduce(
    item._subItems,
    (prev, cur) => {
      const subItems = flatSubItems(cur);

      return [...prev, cur, ...subItems];
    },
    []
  );
};

export const mapOkrListToTreeList = (list: OkrItem[]) => {
  const itemTree: OkrItem[] = [];
  const lookup = keyBy(list, 'id');

  list.sort((a, b) => a.orderNumber - b.orderNumber);

  forEach(list, (item) => {
    const parentId: number = reduce(
      item.pathIds,
      (res, curId) => (curId === item.id ? res : curId),
      null
    );
    const p = lookup[parentId];
    if (p) {
      p.children = p.children || [];
      p.children.push(item);
      p.children.sort((a, b) => a.orderNumber - b.orderNumber);
    } else {
      itemTree.push(item);
    }
  });

  const tree = reduce(
    itemTree,
    (prev, cur, index) => {
      const type = getOkrType(cur);

      if (type === OkrFormType.Objective) {
        const objective = mapObjective(cur, null, OKR_ROW_FIRST_LEVEL, true);
        const subItems = flatSubItems(objective);

        return [...prev, objective, ...subItems];
      } else {
        return prev;
      }
    },
    []
  );

  return tree;
};

export const mapOkrItemListToOKRList = (list: OkrItem[]): OkrSelectedItem[] =>
  list.map((item) => {
    const type = getOkrType(item);

    return type === OkrFormType.Objective
      ? { objective: mapModelObjective(item) }
      : type === OkrFormType.KeyResult
      ? { kr: mapModelKeyResult(item) }
      : type === OkrFormType.Task
      ? { task: mapModelOkrTask(item) }
      : {};
  });

export const filterOkrTreeByTimeline = (okr: Okr, timelineId: number) => {
  if (!okr?.childTimelines?.some((e) => e.id === timelineId)) {
    return;
  }
  const children = [];
  okr.children.forEach((e) => {
    const child = filterOkrTreeByTimeline(e, timelineId);
    if (child) {
      children.push(child);
    }
  });
  const keyResults = okr.keyResults.filter(
    (e) => e.timeline?.id === timelineId
  );
  const tasks = okr.tasks.filter((e) => e.timelineId === timelineId);
  const _okr = { ...okr, children, keyResults, tasks };
  return _okr;
};

export const getOkrCacheKeyBySpace = (cachekey: string, spaceId: number) => {
  return `${cachekey}:space:${spaceId}`;
};

export const mapTimelineTreeToLocalTree = (
  tree: OkrTimelineTree
): TimelineTreeItem => {
  return {
    id: tree.entity?.id,
    title: tree.entity?.title,
    startDate: tree.entity?.startDate,
    endDate: tree.entity?.endDate,
    timelineCategory: tree.entity?.timelineCategory,
    children: tree.children?.map(mapTimelineTreeToLocalTree)
  };
};

export const getOkrFilterCacheKeyBySpace = (
  cachekey: string,
  spaceId: number
) => {
  return `${cachekey}:spaceFilter:${spaceId}`;
};

export const mappingLinkedOkr = (objective: Okr, linked: Okr): Okr => {
  return {
    ...objective,
    ...linked,
    metric: linked.metric || objective.metric,
    id: objective.id,
    linkedOkr: objective.linkedOkr,
    orderNumber: objective.orderNumber,
    children: objective.children,
    keyResults: objective.keyResults,
    tasks: objective.tasks,
    childTimelines: objective.childTimelines
  };
};

export const mappingLinkedKr = (
  kr: OkrKeyResult,
  linked: OkrKeyResult
): OkrKeyResult => {
  return {
    ...kr,
    ...linked,
    id: kr.id,
    items: kr.items,
    isEditable: kr.isEditable,
    timeline: kr.timeline,
    orderNumber: kr.orderNumber,
    linkedKeyResult: kr.linkedKeyResult
  };
};

export const getUpdatedSubItemsFromObjective = (
  origin: VirtualOkrItem,
  newData: Okr,
  items: Dictionary<VirtualOkrItem>,
  update: UpdateTrigger
): OkrItemChange => {
  const result: OkrItemChange = {
    newItems: [],
    removedItems: [],
    updatedItems: []
  };
  const newDataIds = new Set([
    ...newData.children.map((child) => child.id),
    ...newData.keyResults.map((kr) => kr.id),
    ...newData.tasks.map((task) => task.id)
  ]);

  result.removedItems = origin._subItems.filter(
    (subItem) => !newDataIds.has(subItem.id)
  );

  // handle Objectives
  newData.children.forEach((child) => {
    const existing = origin._subItems.find((e) => e.id === child.id);
    const existed = existing && items[existing._id];

    if (existed) {
      // update Objective
      if (
        update?.objective &&
        (!update.objectiveType || child.objectiveType === update.objectiveType)
      ) {
        result.updatedItems.push(
          mapObjectiveToVirtualObjective(child, existed)
        );
      }
      return;
    }

    // new Objective
    result.newItems.push(
      mapOriginItemToVirtualOkr({
        item: child,
        parent: origin,
        lvl: origin._lvl + 1,
        formType: OkrFormType.Objective,
        subItems: [],
        visible: origin._isExpanded
      })
    );
  });

  // handle Key Results
  newData.keyResults.forEach((kr) => {
    const existing = origin._subItems.find((e) => e.id === kr.id);
    const existed = existing && items[existing._id];

    if (existed) {
      // update KR
      if (update?.keyResult) {
        result.updatedItems.push(mapKeyResultToVirtualKeyResult(kr, existed));
      }
      return;
    }
    result.newItems.push(
      mapOriginItemToVirtualOkr({
        item: kr,
        parent: origin,
        lvl: origin._lvl + 1,
        formType: OkrFormType.KeyResult,
        subItems: [],
        visible: origin._isExpanded
      })
    );
  });

  // handle Tasks
  newData.tasks.forEach((task) => {
    const existing = origin._subItems.find((e) => e.id === task.id);
    const existed = existing && items[existing._id];

    if (existed) {
      result.updatedItems.push(mapOkrTaskToVirtualTask(task, existed));
      return;
    }
    result.newItems.push(
      mapOriginItemToVirtualOkr({
        item: task,
        parent: origin,
        lvl: origin._lvl + 1,
        formType: OkrFormType.Task,
        subItems: [],
        visible: origin._isExpanded
      })
    );
  });

  return result;
};

export const getUpdatedSubItemsFromKeyResult = (
  origin: VirtualOkrItem,
  newData: OkrKeyResult,
  items: Dictionary<VirtualOkrItem>
): OkrItemChange => {
  const result: OkrItemChange = {
    newItems: [],
    removedItems: [],
    updatedItems: []
  };
  const newDataIds = new Set(newData.items.map((item) => item.id));

  result.removedItems = origin._subItems.filter(
    (subItem) => !newDataIds.has(subItem.id)
  );

  newData.items.forEach((item) => {
    const existing = origin._subItems.find((e) => e.id === item.id);
    const existed = existing && items[existing._id];

    if (existed) {
      result.updatedItems.push(mapOkrTaskToVirtualTask(item, existed));
    } else {
      result.newItems.push(
        mapOriginItemToVirtualOkr({
          item: item,
          parent: origin,
          lvl: origin._lvl + 1,
          formType: OkrFormType.Task,
          subItems: [],
          visible: origin._isExpanded
        })
      );
    }
  });

  return result;
};
