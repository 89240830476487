import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DynamicBreadcrumbDataService } from 'src/app/site-management/dynamic-field/_services/dynamic-breadcrumb-data.service';
import { DynamicFieldDataService } from 'src/app/site-management/dynamic-field/_services/dynamic-field-data.service';
import { PermissionCheckService } from 'src/app/site-management/role-permission/_services/permission-check.service';
import {
  selectCurrentProject,
  SiteManagementState
} from 'src/app/site-management/_store/site-management';
import { LicenseDataService } from 'src/app/site-management/_services/license-data.service';
import { routerObject } from '../..';
import { Project } from '../../_models';
import { LicenseCode, ProjectType } from '../../_utils';
import { BreadcrumbItemStorage } from '../../_models/dynamic-field-breadcrumb.model';

export interface BreadcumbOption {
  text: string;
  routerLink: string;
  isHidden?: boolean;
}

@Component({
  selector: 'app-breadcumb-item',
  templateUrl: './breadcumb-item.component.html',
  styleUrls: ['./breadcumb-item.component.scss']
})
export class BreadcumbItemComponent implements OnInit, OnChanges, OnDestroy {
  @Input() activePath = '';
  @Input() replaceKeyValue: { [key: string]: string } = {};
  @Input() isLastInBreadcumb = false;
  @Input() label = '';
  @Input() replaceText = '';
  @Input() breadcrumbProject: Project;
  project: Project;
  groupOptions: BreadcumbOption[][] = [];
  options: BreadcumbOption[] = [];
  routerObject = routerObject;
  breadcrumbItemStorage: BreadcrumbItemStorage;
  destroyed$ = new Subject<void>();

  constructor(
    private router: Router,
    private permissionCheckService: PermissionCheckService,
    private licenseDataService: LicenseDataService,
    private store: Store<SiteManagementState>,
    private dynamicFieldDataService: DynamicFieldDataService,
    private dynamicBreadcrumbDataService: DynamicBreadcrumbDataService
  ) {}

  ngOnInit(): void {
    this.observeProject();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.breadcrumbItemStorage =
      this.dynamicBreadcrumbDataService.breadcrumbItemStorage;
    this.buildData();
  }

  ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  observeProject() {
    this.store
      .select(selectCurrentProject)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((project) => {
        if (project && !this.breadcrumbProject) {
          this.project = project;
          this.buildData();
        }
      });
  }

  isValidLicense(key: LicenseCode) {
    return this.licenseDataService.canActive(key);
  }

  isKanban() {
    return (
      (this.breadcrumbProject || this.project)?.type === ProjectType.KANBAN
    );
  }

  buildData() {
    this.groupOptions = [
      // Main Menu
      [
        {
          text: this.routerObject.workCalendar.text,
          routerLink: this.routerObject.workCalendar.fullPath
        },
        {
          text: this.routerObject.widgetBoard.text,
          routerLink: this.routerObject.widgetBoard.fullPath
          // isHidden: !this.isValidLicense(LicenseCode.documentManagement),
        },
        {
          text: this.routerObject.dashboard.text,
          routerLink: this.routerObject.dashboard.fullPath
          // isHidden: !this.isValidLicense(LicenseCode.documentManagement),
        },
        {
          text: this.routerObject.leave.text,
          routerLink: this.routerObject.leave.fullPath,
          isHidden: !this.isValidLicense(LicenseCode.leaveTracking)
        },
        {
          text: this.routerObject.workTracking.text,
          routerLink: this.routerObject.workTracking.fullPath,
          isHidden: !this.isValidLicense(LicenseCode.dailyReport)
        },
        {
          text: this.routerObject.timesheet.text,
          routerLink: this.routerObject.timesheet.fullPath,
          isHidden: !this.isValidLicense(LicenseCode.timesheet)
        },
        {
          text: this.routerObject.taskMgmt.text,
          routerLink: this.routerObject.taskMgmt.fullPath,
          isHidden: !this.isValidLicense(LicenseCode.taskManagement)
        },
        {
          text: this.routerObject.document.text,
          routerLink: this.routerObject.document.fullPath,
          isHidden: !this.isValidLicense(LicenseCode.documentManagement)
        },
        {
          text: this.routerObject.okr.text,
          routerLink: this.routerObject.okr.fullPath,
          isHidden: !this.isValidLicense(LicenseCode.okr)
        },
        {
          text: this.routerObject.org.text,
          routerLink: this.routerObject.org.fullPath
        },
        {
          text: this.routerObject.hr.text,
          routerLink: this.routerObject.hr.fullPath,
          isHidden: !this.isValidLicense(LicenseCode.hr)
        },
        {
          text: this.routerObject.auditLog.text,
          routerLink: this.routerObject.auditLog.fullPath
        },
        {
          text: this.routerObject.customer.text,
          routerLink: this.routerObject.customer.fullPath
        },
        // dynamic parent
        ...this.breadcrumbItemStorage?.parents
      ],
      // Leave childs
      [
        {
          text: this.routerObject.leaveDashboard.text,
          routerLink: this.routerObject.leaveDashboard.fullPath
        },
        {
          text: this.routerObject.leaveRequest.text,
          routerLink: this.routerObject.leaveRequest.fullPath
        },
        {
          text: this.routerObject.leaveApproval.text,
          routerLink: this.routerObject.leaveApproval.fullPath
        },
        {
          text: this.routerObject.leaveBalance.text,
          routerLink: this.routerObject.leaveBalance.fullPath
        },
        {
          text: this.routerObject.leaveReport.text,
          routerLink: this.routerObject.leaveReport.fullPath
        },
        {
          text: this.routerObject.leaveSetting.text,
          routerLink: this.routerObject.leaveSetting.fullPath
        }
      ],
      // Leave Setting childs
      [
        {
          text: this.routerObject.leaveSettingCategory.text,
          routerLink: this.routerObject.leaveSettingCategory.fullPath
        },
        {
          text: this.routerObject.leaveSettingLeaveType.text,
          routerLink: this.routerObject.leaveSettingLeaveType.fullPath
        },
        {
          text: this.routerObject.leaveSettingCarryOver.text,
          routerLink: this.routerObject.leaveSettingCarryOver.fullPath
        }
      ],
      // Work Tracking childs
      [
        {
          text: this.routerObject.workTrackingMyTracking.text,
          routerLink: this.routerObject.workTrackingMyTracking.fullPath
        },
        {
          text: this.routerObject.workTrackingManager.text,
          routerLink: this.routerObject.workTrackingManager.fullPath
        },
        {
          text: this.routerObject.workTrackingSetting.text,
          routerLink: this.routerObject.workTrackingSetting.fullPath
        }
      ],
      // Timesheet childs
      [
        {
          text: this.routerObject.timesheetMyLogwork.text,
          routerLink: this.routerObject.timesheetMyLogwork.fullPath
        },
        {
          text: this.routerObject.timesheetReport.text,
          routerLink: this.routerObject.timesheetReport.fullPath
        },
        {
          text: this.routerObject.timesheetSetting.text,
          routerLink: this.routerObject.timesheetSetting.fullPath
        }
      ],
      // Task Management childs
      [
        {
          text: this.routerObject.taskMgmtProject.text,
          routerLink: this.routerObject.taskMgmtProject.fullPath
        },
        {
          text: this.routerObject.taskMgmtTaskList.text,
          routerLink: this.routerObject.taskMgmtTaskList.fullPath
        },
        {
          text: this.routerObject.taskMgmtMyFilter.text,
          routerLink: this.routerObject.taskMgmtMyFilter.fullPath
        },
        {
          text: this.routerObject.taskMgmtSharedFilter.text,
          routerLink: this.routerObject.taskMgmtSharedFilter.fullPath
        }
      ],
      // Project childs
      [
        {
          text: this.routerObject.taskMgmtGantt.text,
          routerLink: this.routerObject.taskMgmtGantt.fullPath
        },
        {
          text: this.isKanban()
            ? this.routerObject.taskMgmtKanban.text
            : this.routerObject.taskMgmtActiveSprint.text,
          routerLink: this.isKanban()
            ? this.routerObject.taskMgmtKanban.fullPath
            : this.routerObject.taskMgmtActiveSprint.fullPath
        },
        {
          text: this.routerObject.taskMgmtRelease.text,
          routerLink: this.routerObject.taskMgmtRelease.fullPath,
          isHidden: this.licenseDataService.isKanbanLicense()
        },
        {
          text: this.routerObject.taskMgmtBacklog.text,
          routerLink: this.routerObject.taskMgmtBacklog.fullPath
        },
        {
          text: this.routerObject.taskMgmtEpic.text,
          routerLink: this.routerObject.taskMgmtEpic.fullPath
        },
        {
          text: this.routerObject.taskMgmtTaskTemplate.text,
          routerLink: this.routerObject.taskMgmtTaskTemplate.fullPath
        },
        {
          text: this.routerObject.taskMgmtStorylineBoard.text,
          routerLink: this.routerObject.taskMgmtStorylineBoard.fullPath
        },
        {
          text: this.routerObject.taskMgmtProjectSetting.text,
          routerLink: this.routerObject.taskMgmtProjectSetting.fullPath
        }
      ],
      // Project Setting childs
      [
        {
          text: this.routerObject.taskMgmtProjectGeneral.text,
          routerLink: this.routerObject.taskMgmtProjectGeneral.fullPath
        },
        {
          text: this.routerObject.taskMgmtProjectMember.text,
          routerLink: this.routerObject.taskMgmtProjectMember.fullPath
        },
        {
          text: this.routerObject.taskMgmtProjectBoardSetting.text,
          routerLink: this.routerObject.taskMgmtProjectBoardSetting.fullPath
        },
        {
          text: this.routerObject.taskMgmtProjectStatusGroup.text,
          routerLink: this.routerObject.taskMgmtProjectStatusGroup.fullPath
        },
        {
          text: this.routerObject.taskMgmtProjectTaskType.text,
          routerLink: this.routerObject.taskMgmtProjectTaskType.fullPath
        },
        {
          text: this.routerObject.taskMgmtProjectPriority.text,
          routerLink: this.routerObject.taskMgmtProjectPriority.fullPath
        },
        {
          text: this.routerObject.taskMgmtProjectStoryline.text,
          routerLink: this.routerObject.taskMgmtProjectStoryline.fullPath
        },
        {
          text: this.routerObject.taskMgmtProjectLabel.text,
          routerLink: this.routerObject.taskMgmtProjectLabel.fullPath
        },
        {
          text: this.routerObject.taskMgmtProjectHistory.text,
          routerLink: this.routerObject.taskMgmtProjectHistory.fullPath
        },
        {
          text: this.routerObject.taskMgmtProjectTaskTemplate.text,
          routerLink: this.routerObject.taskMgmtProjectTaskTemplate.fullPath
        },
        {
          text: this.routerObject.taskMgmtProjectGit.text,
          routerLink: this.routerObject.taskMgmtProjectGit.fullPath
        },
        {
          text: this.routerObject.taskMgmtProjectSwimlane.text,
          routerLink: this.routerObject.taskMgmtProjectSwimlane.fullPath
        },
        {
          text: this.routerObject.taskMgmtProjectSlackIntegration.text,
          routerLink: this.routerObject.taskMgmtProjectSlackIntegration.fullPath
        },
        {
          text: this.routerObject.taskMgmtProjectThreshold.text,
          routerLink: this.routerObject.taskMgmtProjectThreshold.fullPath
        }
      ],
      // Organization childs
      [
        {
          text: this.routerObject.orgLocation.text,
          routerLink: this.routerObject.orgLocation.fullPath
        },
        {
          text: this.routerObject.orgOraganizationTreeSetting.text,
          routerLink: this.routerObject.orgOraganizationTreeSetting.fullPath
        },
        {
          text: this.routerObject.orgShiftSetting.text,
          routerLink: this.routerObject.orgShiftSetting.fullPath
        },
        {
          text: this.routerObject.orgGroup.text,
          routerLink: this.routerObject.orgGroup.fullPath
        },
        {
          text: this.routerObject.orgTeam.text,
          routerLink: this.routerObject.orgTeam.fullPath
        },
        {
          text: this.routerObject.orgUser.text,
          routerLink: this.routerObject.orgUser.fullPath
        },
        {
          text: this.routerObject.orgRolePermission.text,
          routerLink: this.routerObject.orgRolePermission.fullPath
        },
        {
          text: this.routerObject.orgHoliday.text,
          routerLink: this.routerObject.orgHoliday.fullPath
        },
        {
          text: this.routerObject.orgBilling.text,
          routerLink: this.routerObject.orgBilling.fullPath
        },
        {
          text: this.routerObject.orgIntegration.text,
          routerLink: this.routerObject.orgIntegration.fullPath
        },
        {
          text: this.routerObject.orgIntegrationSlack.text,
          routerLink: this.routerObject.orgIntegrationSlack.fullPath
        },
        {
          text: this.routerObject.orgIntegrationJira.text,
          routerLink: this.routerObject.orgIntegrationJira.fullPath
        }
      ],
      // Organization Tree childs
      [
        {
          text: this.routerObject.orgOraganizationTree.text,
          routerLink: this.routerObject.orgOraganizationTree.fullPath
        },
        {
          text: this.routerObject.orgOraganizationTreeCompanyStructure.text,
          routerLink:
            this.routerObject.orgOraganizationTreeCompanyStructure.fullPath
        },
        {
          text: this.routerObject.orgOraganizationTreeJobTitle.text,
          routerLink: this.routerObject.orgOraganizationTreeJobTitle.fullPath
        }
      ],
      // Hr childs
      [
        {
          text: this.routerObject.hrHiringBoard.text,
          routerLink: this.routerObject.hrHiringBoard.fullPath
        },
        {
          text: this.routerObject.hrPolicy.text,
          routerLink: this.routerObject.hrPolicy.fullPath
        },
        {
          text: this.routerObject.hrBenefitRule.text,
          routerLink: this.routerObject.hrBenefitRule.fullPath
        },
        {
          text: this.routerObject.hrOnboard.text,
          routerLink: this.routerObject.hrOnboard.fullPath
        },
        {
          text: this.routerObject.hrSettings.text,
          routerLink: this.routerObject.hrSettings.fullPath
        },
        {
          text: this.routerObject.hrPaySchedule.text,
          routerLink: this.routerObject.hrPaySchedule.fullPath
        },
        {
          text: this.routerObject.hrSalaryProfile.text,
          routerLink: this.routerObject.hrSalaryProfile.fullPath
        },
        {
          text: this.routerObject.hrPayroll.text,
          routerLink: this.routerObject.hrPayroll.fullPath
        }
      ],
      [
        {
          text: this.routerObject.hrOnboardMember.text,
          routerLink: this.routerObject.hrOnboardMember.fullPath
        },
        {
          text: this.routerObject.hrHRMember.text,
          routerLink: this.routerObject.hrHRMember.fullPath
        },
        {
          text: this.routerObject.hrOnboardSettings.text,
          routerLink: this.routerObject.hrOnboardSettings.fullPath
        },
        {
          text: this.routerObject.hrHRboardSettings.text,
          routerLink: this.routerObject.hrHRboardSettings.fullPath
        }
      ],
      // Billing childs
      [
        {
          text: this.routerObject.orgBillingChangePlan.text,
          routerLink: this.routerObject.orgBillingChangePlan.fullPath
        }
      ],
      // Shift childs
      [
        {
          text: this.routerObject.orgShift.text,
          routerLink: this.routerObject.orgShift.fullPath
        },
        {
          text: this.routerObject.orgShiftProfile.text,
          routerLink: this.routerObject.orgShiftProfile.fullPath
        }
      ],
      // Role & Permission childs
      [
        {
          text: this.routerObject.orgRole.text,
          routerLink: this.routerObject.orgRole.fullPath
        },
        {
          text: this.routerObject.orgPermission.text,
          routerLink: this.routerObject.orgPermission.fullPath
        }
      ],
      // Okr childs
      [
        {
          text: this.routerObject.okrMyBoard.text,
          routerLink: this.routerObject.okrMyBoard.fullPath
        },
        {
          text: this.routerObject.okrReport.text,
          routerLink: this.routerObject.okrReport.fullPath
        },
        {
          text: this.routerObject.okrBoard.text,
          routerLink: this.routerObject.okrBoard.fullPath
        },
        {
          text: this.routerObject.okrSetting.text,
          routerLink: this.routerObject.okrSetting.fullPath
        }
      ],
      // okr settings child
      [
        {
          text: this.routerObject.okrSettingSpaceMembers.text,
          routerLink: this.routerObject.okrSettingSpaceMembers.fullPath
        }
      ],
      [
        {
          text: this.routerObject.settings.text,
          routerLink: this.routerObject.settings.fullPath
        }
      ],
      [
        {
          text: this.routerObject.dynamicField.text,
          routerLink: this.routerObject.dynamicField.fullPath
        },
        {
          text: this.routerObject.language.text,
          routerLink: this.routerObject.language.fullPath
        },
        {
          text: this.routerObject.notificationSetting.text,
          routerLink: this.routerObject.notificationSetting.fullPath
        },
        {
          text: this.routerObject.documentTemplateSetting.text,
          routerLink: this.routerObject.documentTemplateSetting.fullPath
        },
        {
          text: this.routerObject.locationSetting.text,
          routerLink: this.routerObject.locationSetting.fullPath
        }
      ],

      // dynamic childs
      ...(this.breadcrumbItemStorage?.childs as [])
    ];

    this.options = (
      this.groupOptions.find((groupOption) =>
        groupOption.some((option) => option.routerLink === this.activePath)
      ) || []
    ).filter((e) => !e.isHidden && this.isVisibleItemBySetting(e.routerLink));
  }

  isVisibleItemBySetting(routerLink: string) {
    const objKey = Object.keys(routerObject).find(
      (key) => routerObject[key].fullPath === routerLink
    );
    return this.isVisibleHash(routerObject[objKey]?.hash);
  }

  isVisibleHash(hash?: string) {
    const field = this.dynamicFieldDataService.getDynamicFieldByHash(hash);
    return field ? field.isVisible : true;
  }

  getNavigateLink(routerLink: string, replaceKeyValue = this.replaceKeyValue) {
    const replaceKeys = Object.keys(replaceKeyValue);
    let link = routerLink;
    replaceKeys.forEach((key) => {
      link = link.replace(key, replaceKeyValue[key]);
    });
    return link;
  }

  navigateTo(routerLink: string) {
    const link = this.getNavigateLink(routerLink);
    this.router.navigate([link]);
  }

  get selectedOption() {
    return this.options.find((e) => e.routerLink === this.activePath);
  }
}
