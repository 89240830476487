import { AUDIT_ACTION, FieldHistory } from '../_utils';
import { TypeOfLeave } from './type-of-leave.model';

export interface AuditLog {
  id: number;
  field: string;
  createdAt: string;
  newValue: string;
  oldValue: string;
  author: Author;
  mainFeatureType: string;
  action: AUDIT_ACTION;
  targetObject: TargetObject;
}

export interface TargetObject {
  id: number;
  key: string;
  name: string;
  avatar: string;
  typeOfLeave: TypeOfLeave;
}

export interface Author {
  id: number;
  fullName: string;
  avatar: string;
}

export class AuditLogSearch {
  objectId?: number;
  authorIds?: number[];
  searchText?: string;
  fromTime?: string;
  toTime?: string;
  featureTypes?: string[];
  actions?: AUDIT_ACTION[];
  projectId?: number;

  constructor(data?: AuditLogSearch) {
    this.objectId = data?.objectId;
    this.authorIds = data?.authorIds || [];
    this.searchText = data?.searchText || '';
    this.fromTime = data?.fromTime || '';
    this.toTime = data?.toTime || '';
    this.featureTypes = data?.featureTypes || [];
    this.actions = data?.actions || [];
  }
}

export enum AuditFieldType {
  CONTENT = 'content',
  DATE = 'date',
  TIMELINE = 'timeline',
  STRING = 'string',
  BOOLEAN = 'boolean',
  NUMBER = 'number',
  AVATAR = 'avatar',
  MULTI_OPTIONS = 'multiOptions',
  PROJECT_SETTING = 'projectSetting',
  PROJECT = 'project',
  TIME = 'time',
  ATTACHMENTS = 'attachments',
}

export const auditFieldsWithType: Record<AuditFieldType, FieldHistory[]> = {
  [AuditFieldType.CONTENT]: [FieldHistory.description, FieldHistory.comment],
  [AuditFieldType.DATE]: [
    FieldHistory.dueDate,
    FieldHistory.startDate,
    FieldHistory.endDate,
    FieldHistory['contributor.startDate'],
    FieldHistory['contributor.endDate'],
    FieldHistory['contributor.dueDate'],
  ],
  [AuditFieldType.TIMELINE]: [FieldHistory.timeline],
  [AuditFieldType.STRING]: [
    FieldHistory.name,
    FieldHistory.resultType,
    FieldHistory.riskLevel,
    FieldHistory.category,
    FieldHistory.indicator,
  ],
  [AuditFieldType.BOOLEAN]: [
    FieldHistory.storylineFlg,
    FieldHistory.privateFlg,
    FieldHistory.taskTemplate
  ],
  [AuditFieldType.NUMBER]: [
    FieldHistory.objectiveType,
    FieldHistory.progress,
    FieldHistory.leaveDayUsed,
    FieldHistory.parentWeight,
    FieldHistory.weight,
    FieldHistory.expected,
    FieldHistory.leaveDayAllowed,
    FieldHistory.current,
    FieldHistory.start,
    FieldHistory.estimate,
    FieldHistory.storyPoint,
    FieldHistory.metricValue,
    FieldHistory.currentMetricValue,
    FieldHistory.startMetricValue,
    FieldHistory.justify,
    FieldHistory['contributor.storyPoint'],
    FieldHistory['contributor.progress'],
  ],
  [AuditFieldType.AVATAR]: [
    FieldHistory.assignee,
    FieldHistory.reporter,
    FieldHistory.stakeHolder,
    FieldHistory.cc,
    FieldHistory.contributor,
  ],
  [AuditFieldType.MULTI_OPTIONS]: [
    FieldHistory.label,
    FieldHistory.versions,
    FieldHistory.okrGroups,
    FieldHistory.okrTeams,
  ],
  [AuditFieldType.PROJECT_SETTING]: [
    FieldHistory.storyline,
    FieldHistory.labelProject,
    FieldHistory.boardStatus,
    FieldHistory.projectMember,
    FieldHistory.Priority,
    FieldHistory.taskType,
    FieldHistory.boardColumnProject,
    FieldHistory.boardColumn
  ],
  [AuditFieldType.PROJECT]: [FieldHistory.projects],
  [AuditFieldType.TIME]: [
    FieldHistory.startTime,
    FieldHistory.endTime,
    FieldHistory['contributor.startTime'],
    FieldHistory['contributor.endTime'],
  ],
  [AuditFieldType.ATTACHMENTS]: [FieldHistory.attachments],
}
