<a class="btn-link btn-search-overlay"
  nz-button
  #dropdown
  nz-dropdown
  [nzClickHide]="!config.multiple"
  [nzDropdownMenu]="nzDropdown"
  [nzDisabled]="!canEdit"
  [nzTrigger]="'click'"
  (nzVisibleChange)="onOpenSearch($event)"
  stopEvent>
  <ng-container *ngIf="config?.selectedTemplate">
    <ng-container *ngTemplateOutlet="config.selectedTemplate; context: {selecteds : config.selecteds}"></ng-container>
  </ng-container>
</a>

<nz-dropdown-menu #nzDropdown="nzDropdownMenu">
  <div nz-menu
       class="custom-search-dropdown-menu search-overlay-custom-dropdown-menu">
       <div class="header" *ngIf="config.title || config.searchable">
        <div class="title" *ngIf="config.title">{{config.title | translate }}</div>

        <app-search-input
          [text]="searchText"
          (keywordChange)="onSearchText($event)"
          *ngIf="config.searchable"
        ></app-search-input>
      </div>
      <div class="option-list relative" [style.height.px]="200">
        <ng-container *ngFor="let item of config.items">
          <div class="custom-option" nz-menu-item [ngClass]="{'selected': isSelected(item)}"
            (click)="toggleItem(item, dropdown)">
            <ng-container *ngIf="config.optionTemplate">
              <ng-container *ngTemplateOutlet="config.optionTemplate; context: {item : item}"></ng-container>
            </ng-container>
          </div>
        </ng-container>

        <div
          class="flex items-center justify-center h-full"
          *ngIf="!config.loading && config.items.length === 0"
        >
          <app-i18n [key]="'No options'"></app-i18n>
        </div>
        <div
          class="flex items-center justify-center h-full absolute top-0 left-0 w-full opacity-50"
          *ngIf="config.loading"
        >
          <span class="spinner-border"></span>
        </div>
      </div>
      <div class="footer py-0" *ngIf="config.footerTemplate" nz-menu-item>
        <ng-container *ngTemplateOutlet="config.footerTemplate"></ng-container>
      </div>
  </div>
</nz-dropdown-menu>
