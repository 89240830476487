<div class="cell-due-date"
     *ngIf="dueDate | dueDateString as dueDateString"
     [ngStyle]="{ background: showBackground ? dueDateString?.color : '' }"
     (click)="canEdit && dp.show()"
     stopEvent
     [ngClass]="{'background-view' : showBackground }"
     [nz-tooltip]="dueDateString.dueDateDescription"
     [nzTooltipMouseLeaveDelay]="0.01"
     >
  <mat-icon *ngIf="showIcon" class="mr-2 icon-clock" [svgIcon]="'clock'"></mat-icon>

  <span class="text-center d-block pointer text-due-date"
        [ngClass]="{'fw-5': dueDate}"
        [innerHTML]="showBackground ? dueDateString.text : dueDateString.dueDateString"></span>
  <!-- <button class="btn btn-icon btn-light btn-due-date" [ngClass]="{'d-flex': dp.isOpen}" (click)="dp.show()" *ngIf="!dueDate"  [title]="'Set due date'">
    <span class="material-icons-outlined">
      edit
    </span>
  </button> -->
  <input class="due-date-picker"
    [(ngModel)]="selectedDate"
    #dp="bsDatepicker"
    bsDatepicker
    [bsConfig]="bsConfig"
    placement="bottom right"
    (bsValueChange)="onDueDateChange($event)">
</div>
