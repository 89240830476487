import { Component, Input } from '@angular/core';
import { User } from 'src/app/shared/_models';
import { UserService } from 'src/app/site-management/user/_services/user.service';
import { SearchOverlayBaseComponent } from '../search-overlay-base.component';
import { stringify } from 'query-string';
import { FIRST_PAGE, OPTIONS_PER_PAGE } from 'src/app/shared/_utils';

@Component({
  selector: 'app-search-overlay-user',
  templateUrl: './search-overlay-user.component.html',
})
export class SearchOverlayUserComponent extends SearchOverlayBaseComponent<User> {
  @Input() includeUnassigned = false;

  constructor(
    private userService: UserService,
  ) {
    super();
    this.config.title = 'User';
  }

  getSearchFn() {
    return (keyword?: string) => {
      this.config.loading = true;
      this.config.searchSubscription?.unsubscribe();
      const query = stringify({
        page: FIRST_PAGE,
        size: OPTIONS_PER_PAGE,
        keyword,
      });
      this.config.searchSubscription = this.userService.getWithQuery(query).subscribe(
        (res) => {
          this.config.items = res;
          this.config.loading = false;
          this.config.triggerItems$.next(res);
        },
        (error) => {
          this.config.items = [];
          this.config.loading = false;
          this.config.triggerItems$.next([]);
        }
      );
    };
  }
}
