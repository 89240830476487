import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[stopDefaultEvent]'
})
export class StopDefaultEventDirective {
  @HostListener('click', ['$event'])
  handleClick(event: MouseEvent): void {
    if (
      event.button === 0 &&
      !event.ctrlKey &&
      !event.altKey &&
      !event.metaKey
    ) {
      event.preventDefault();
    }
  }
}