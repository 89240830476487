interface ScrollOptions {
  position: number;
  behavior?: 'smooth' | 'auto';
  selector: string;
  onError?: (error: Error) => void;
}

type ScrollDirection = 'top' | 'bottom' | 'left' | 'right';

/**
 * Scrolls the CDK virtual scroll viewport to a specified position in any direction
 * @param direction Direction to scroll ('top', 'bottom', 'left', or 'right')
 * @param options Scroll options including position, behavior, selector, and error handler
 * @throws Error if viewport element is not found
 */
export const scrollViewportTo = (
  direction: ScrollDirection,
  options: ScrollOptions
): void => {
  const {
    position,
    behavior = 'smooth',
    selector,
    onError
  } = options;

  const viewport = document.querySelector(selector) as HTMLElement | null;

  try {
    if (!viewport) {
      return;
    }

    const scrollParams: ScrollToOptions = { behavior };
    switch (direction) {
      case 'top':
        scrollParams.top = position;
        break;
      case 'bottom':
        scrollParams.top = viewport.scrollHeight - viewport.clientHeight - position;
        break;
      case 'left':
        scrollParams.left = position;
        break;
      case 'right':
        scrollParams.left = viewport.scrollWidth - viewport.clientWidth - position;
        break;
    }

    viewport.scrollTo(scrollParams);
  } catch (error) {
    if (onError && error instanceof Error) {
      onError(error);
    } else {
      throw error;
    }
  }
};
