<div class="cell-text-editable d-flex align-items-center text-ellipsis">
  <div class="text-name text-ellipsis whitespace-nowrap"
       [title]="text"
       *ngIf="!isEditMode"><span class="d-inline-block" [style.width.px]="preWidth"></span>{{text}}</div>

  <input *ngIf="isEditMode" #nameInput type="text" stopEvent class="text-ellipsis edit-name-input"
    [formControl]="control.get(FormCtrlName.TEXT)" (keyup.enter)="save()"
    (keyup.escape)="cancel()">

  <ng-container *ngIf="isEditMode">
    <button class="ml-2 btn btn-icon btn-light btn-cancel" stopEvent (click)="cancel()" [title]="'Cancel' | translate">
      <span class="material-icons-outlined">
        close
      </span>
    </button>

    <button class="ml-2 btn btn-icon btn-light btn-save" stopEvent (click)="save()" [title]="'Save' | translate">
      <span class="material-icons-outlined">
        done
      </span>
    </button>
  </ng-container>

  <button class="btn btn-icon btn-light btn-edit-name" stopEvent (click)="showEditMode()" [title]="'Edit' | translate"
    *ngIf="!isEditMode && canEdit">
    <span class="material-icons-outlined">
      edit
    </span>
  </button>
</div>
<app-form-error [form]="control"
  [maxLengthMessage]="maxLengthErrorMessage"
  [controlName]="FormCtrlName.TEXT"></app-form-error>
