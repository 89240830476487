import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UserService } from 'src/app/site-management/user/_services/user.service';
import { SearchControlComponent } from '../base/search-base.component';
import { Employee } from './search-employee';
import * as queryString from 'query-string';
import { debounceTime, startWith } from 'rxjs/operators';
import { OPTIONS_PER_PAGE } from 'src/app/shared/_utils';

@Component({
  selector: 'app-search-employee',
  templateUrl: './search-employee.component.html',
  styleUrls: ['./search-employee.component.scss']
})
export class SearchEmployeeComponent extends SearchControlComponent<Employee> implements OnInit {
  constructor(private userService: UserService) {
    super();
  }

  ngOnInit(): void {
    this.typing$.pipe(startWith(''), debounceTime(500)).subscribe((res) => {
      this.searchDto.keyword = res;
      this.fetch();
    });
  }

  fetch(): void {
    const query = queryString.stringify({
      ...this.searchDto,
      size: OPTIONS_PER_PAGE
    });

    this.sub?.unsubscribe();
    this.sub = this.userService.getWithQuery(query).subscribe((res) => {
      this.items = res;
    });
  }
}
