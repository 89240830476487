import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import * as queryString from 'query-string';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { AuthService } from 'src/app/authentication/_services/auth.service';
import {
  ActiveSprint,
  CURRENT_PROJECT_KEY,
  LEADER_PROJECTS_KEY,
  List2Res,
  MemberGenerateTask,
  PageInfo,
  Pageable,
  Project,
  ProjectCache,
  ProjectCloneRequest,
  ProjectEditRequest,
  ProjectMember,
  ProjectMemberEdit,
  ProjectMemberSearchParam,
  ProjectMemberUpsert,
  ProjectMemberUser,
  ProjectPlatform,
  ProjectPlatformCreateRequest,
  ProjectPlatformSearchRequest,
  ProjectSearchParam,
  ProjectShort,
  Release,
  Sprint,
  Task,
  TaskStatus,
  WorkItemLabel,
  WorkItemPriority,
  WorkItemType
} from 'src/app/shared';
import { ActiveBoard } from 'src/app/shared/_models/sprint-active.model';
import { environment } from 'src/environments/environment';
import { UserSettingKey } from '../../_store/user-setting/user-setting.model';
import { ActiveBoardFilter } from '../active-board/_models/active-board.model';

@Injectable({
  providedIn: 'root'
})

export class ProjectsService extends EntityCollectionServiceBase<Project> {
  public page$: Observable<PageInfo> = new Observable();
  private defaultQueryParamStr = 'keyword=&page=0&size=10000';

  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private http: HttpClient,
    private authService: AuthService,
    ) {
    super('projects', serviceElementsFactory);
    this.page$ = this.selectors$['page$'];
  }

  getCacheProject(): Project {
    const userId = this.authService.getLoggedUser()?.id || '';
    try {
      const currentProject = JSON.parse(localStorage.getItem(CURRENT_PROJECT_KEY + userId) || null);
      return currentProject;
    } catch (error) {
      return null;
    }
  }

  getCurrentProject(): Project {
    return ProjectCache.shared.get() || this.getCacheProject();
  }

  getUserSettingKey = (key: UserSettingKey): UserSettingKey => {
    const projectId = this.getCurrentProject()?.id;
    return key + `:project:${projectId}` as UserSettingKey;
  }

  removeCurrentProject() {
    const userId = this.authService.getLoggedUser()?.id || '';
    localStorage.removeItem(CURRENT_PROJECT_KEY + userId);
  }

  getCurrentLeadersProjects(): Project[] {
    return JSON.parse(localStorage.getItem(LEADER_PROJECTS_KEY)) || [];
  }

  getLeaderProjects(keyword: string = '') {
    this.setLoading(true);
    return this.http.get<Project[]>(`${environment.apiUrl}/projects/lead-by-user-login?keyword=${keyword}`)
      .pipe(finalize(() => this.setLoading(false)));
  }

  getAllProjects(): Observable<Project[]> {
    return this.getWithQuery(this.defaultQueryParamStr);
  }

  getSubProjects(parentId: number) {
    return this.http.get<Project[]>(`${environment.apiUrl}/projects/${parentId}/sub-projects`);
  }

  getById(projectId: number) {
    return this.http.get<Project>(`${environment.apiUrl}/projects/${projectId}`);
  }

  getByProjectKey(key: string) {
    this.setLoading(true);
    return this.http.get<Project>(`${environment.apiUrl}/projects/key/${key}`)
      .pipe(finalize(() => this.setLoading(false)));
  }

  archiveProject(projectId: number) {
    return this.http.delete<Project>(`${environment.apiUrl}/projects/${projectId}`);
  }

  deleteProject(projectId: number) {
    this.setLoading(true);
    return this.http.delete<Project>(`${environment.apiUrl}/projects/${projectId}/change-status-deleted`)
      .pipe(finalize(() => this.setLoading(false)));
  }

  getProjects(searchParam: ProjectSearchParam): Observable<Project[]> {
    const params = queryString.stringify(searchParam);
    return this.getWithQuery(params);
  }

  getFullProjects(searchParam: ProjectSearchParam): Observable<List2Res<ProjectShort>> {
    const params = queryString.stringify(searchParam);
    return this.http.get<List2Res<ProjectShort>>(`${environment.apiUrl}/projects/all?${params}`);
  }

  getProjectList(searchParam: ProjectSearchParam): Observable<List2Res<Project>> {
    const params = queryString.stringify(searchParam);
    return this.http.get<List2Res<Project>>(`${environment.apiUrl}/projects/?${params}`);
  }

  updateProject(payload: ProjectEditRequest): Observable<Project> {
    return this.http.put<Project>(`${environment.apiUrl}/projects/${payload?.id}`, payload);
  }

  unlinkJira(id: number): Observable<Project> {
    return this.http.put<any>(`${environment.apiUrl}/projects/${id}/unlink-jira`, { id });
  }

  generateProjectKey(projectName: string): Observable<string> {
    this.setLoading(true);
    return this.http.get<{ key: string }>(`${environment.apiUrl}/projects/generate-key/?projectName=${projectName}`)
      .pipe(map(response => {
        this.setLoading(false);
        return response.key;
      },
        finalize(() => this.setLoading(false))
      ));
  }

  verifyExistingProjectKey(key: string): Observable<boolean> {
    this.setLoading(true);
    return this.http.get<{ exist: boolean }>(`${environment.apiUrl}/projects/verify-exist-key?key=${key}`)
      .pipe(map(response => {
        this.setLoading(false);
        return response.exist;
      },
        finalize(() => this.setLoading(false))
      ));
  }

  //#region Sprints & backlog
  getSprintsByProject(projectId: number): Observable<List2Res<Sprint>> {
    this.setLoading(true);
    return this.http.get<List2Res<Sprint>>(`${environment.apiUrl}/projects/${projectId}/board-sprints/?${this.defaultQueryParamStr}`)
      .pipe(finalize(() => this.setLoading(false)));
  }

  getSprints(projectId: number, keyword: string = '', pageable: Pageable = null): Observable<Sprint[]> {
    this.setLoading(true);
    const queryParam = this.getBaseQueryParam(keyword, pageable);
    return this.http.get<List2Res<Sprint>>(`${environment.apiUrl}/projects/${projectId}/board-sprints/?${queryParam}`)
      .pipe(map(response => {
        this.setLoading(false);
        return response.content;
      },
        finalize(() => this.setLoading(false))
      ));
  }

  getTasksBySprint(sprintId: number): Observable<Task> {
    //keyword=&projectIds=1&sprintIds=1&page=0&size=0&sort=name
    return null;
  }

  getWorkItemTypes(projectId: number, keyword: string = '', pageable: Pageable = null): Observable<WorkItemType[]> {
    this.setLoading(true);
    const queryParam = this.getBaseQueryParam(keyword, pageable);
    return this.http.get<List2Res<WorkItemType>>(`${environment.apiUrl}/projects/${projectId}/task-types/?${queryParam}`)
      .pipe(map(response => {
        this.setLoading(false);
        return response.content;
      },
        finalize(() => this.setLoading(false))
      ));
  }

  getWorkItemStatuses(projectId: number, keyword: string = '', pageable: Pageable = null): Observable<TaskStatus[]> {
    this.setLoading(true);
    const queryParam = this.getBaseQueryParam(keyword, pageable);
    return this.http.get<List2Res<TaskStatus>>(`${environment.apiUrl}/projects/${projectId}/board-status/?${queryParam}`)
      .pipe(map(response => {
        this.setLoading(false);
        return response.content;
      },
        finalize(() => this.setLoading(false))
      ));
  }

  getWorkItemPriorities(projectId: number, keyword: string = '', pageable: Pageable = null): Observable<WorkItemPriority[]> {
    this.setLoading(true);
    const queryParam = this.getBaseQueryParam(keyword, pageable);
    return this.http.get<List2Res<WorkItemPriority>>(`${environment.apiUrl}/projects/${projectId}/project-priority/?${queryParam}`)
      .pipe(map(response => {
        this.setLoading(false);
        return response.content;
      },
        finalize(() => this.setLoading(false))
      ));
  }

  getProjectMembers(projectId: number, keyword: string = ''): Observable<ProjectMemberUser[]> {
    this.setLoading(true);
    // const queryParam = this.getBaseQueryParam(keyword);
    const params = queryString.stringify({ keyword });
    return this.http.get<ProjectMemberUser[]>(`${environment.apiUrl}/projects/${projectId}/list-member-users?${params}`)
      .pipe(map(response => {
        this.setLoading(false);
        return response;
      },
        finalize(() => this.setLoading(false))
      ));
  }

  getWorkItemLabels(projectId: number, keyword: string = '', pageable: Pageable = null): Observable<WorkItemLabel[]> {
    this.setLoading(true);
    const queryParam = this.getBaseQueryParam(keyword, pageable);
    return this.http.get<List2Res<WorkItemLabel>>(`${environment.apiUrl}/projects/${projectId}/board-labels/?${queryParam}`)
      .pipe(map(response => {
        this.setLoading(false);
        return response.content;
      },
        finalize(() => this.setLoading(false))
      ));
  }

  getReleases(projectId: number, keyword: string = '', pageable: Pageable = null): Observable<Release[]> {
    this.setLoading(true);
    const queryParam = queryString.stringify( { keyword, ...pageable });
    return this.http.get<List2Res<Release>>(`${environment.apiUrl}/projects/${projectId}/board-release/?${queryParam}`)
      .pipe(map(response => {
        this.setLoading(false);
        return response.content;
      },
        finalize(() => this.setLoading(false))
      ));
  }

  getActiveBoard(projectId: number, filter?: ActiveBoardFilter, pageable?: Pageable) {
    this.setLoading(true);
    const params = {
      ...filter,
      ...pageable
    };

    const paramsStr = queryString.stringify(params);

    return this.http.get<ActiveBoard>(`${environment.apiUrl}/projects/${projectId}/board-sprints/active-sprint?${paramsStr}`)
      .pipe(map(response => {
        this.setLoading(false);
        return response;
      },
        finalize(() => this.setLoading(false))
      ));
  }

  getCurrentSprint(projectId: number) {
    return this.http.get<ActiveSprint>(
      `${environment.apiUrl}/projects/${projectId}/active-sprint`
    );
  }

  moveAllTasks(projectId: number, sprintId: number, data: {
    currentStatusId: number,
    targetStatusId: number
  }) {
    this.setLoading(true);

    return this.http.put<void>(`${environment.apiUrl}/projects/${projectId}/board-sprints/${sprintId}/move-all-task`, data)
      .pipe(map(response => {
        this.setLoading(false);
        return response
      },
        finalize(() => this.setLoading(false))
      ));
  }

  completeSprint(projectId: number, sprintId: number, moveToSprintId?: number) {
    this.setLoading(true);
    let data: any = {}
    if (moveToSprintId) {
      data.moveToSprintId = moveToSprintId
    }

    return this.http.post<void>(`${environment.apiUrl}/projects/${projectId}/board-sprints/${sprintId}/complete-sprint`, data)
      .pipe(map(response => {
        this.setLoading(false);
        return response
      },
        finalize(() => this.setLoading(false))
      ));
  }

  //#endregion

  getMemberList(payload: ProjectMemberSearchParam, pageable: Pageable) {
    const { keyword, groupIds, teamIds, roleIds, includeProjectManager, taskScheduleId } = payload;
    const params = queryString.stringify({
      ...pageable,
      keyword, groupIds, teamIds, roleIds, includeProjectManager, taskScheduleId
    })
    this.setLoading(false);

    return this.http.get<List2Res<ProjectMember>>(`${environment.apiUrl}/projects/${payload.projectId}/members/?${params}`).pipe(
      finalize(() => this.setLoading(false)),
    );
  }

  getGenerateTaskMember(projectId: number, keyword: string, pageable: Pageable) {
    const params = queryString.stringify({ keyword, ...pageable });
    return this.http.get<List2Res<MemberGenerateTask>>(`${environment.apiUrl}/projects/${projectId}/list-member-in-generate-task?${params}`);
  }

  addMembers(projectId: number, payload: ProjectMemberUpsert): Observable<any> {
    this.setLoading(true);
    return this.http.post<any>(`${environment.apiUrl}/projects/${projectId}/add-member`, payload)
      .pipe(finalize(() => this.setLoading(false)));
  }

  editMember(projectId: number, payload: ProjectMemberEdit) {
    return this.http.put(`${environment.apiUrl}/projects/${projectId}/edit-member`, payload);
  }

  removeMember(projectId: number, memberIds: number[] = []) {
    this.setLoading(true);
    return this.http.request<any>('delete', `${environment.apiUrl}/projects/${projectId}/remove-member`, { body: { memberIds } })
      .pipe(finalize(() => this.setLoading(false)));
  }

  private getBaseQueryParam(keyword: string, pageable: Pageable = null) {
    return `keyword=${keyword}&page=${pageable?.page ?? 0}&size=${pageable?.size ?? 10000}`;
  }

  getPlatforms(projectId: number, payload: ProjectPlatformSearchRequest, pageable: Pageable) {
    const params = queryString.stringify({...payload, ...pageable});
    return this.http.get<List2Res<ProjectPlatform>>(`${environment.apiUrl}/projects/${projectId}/platforms?${params}`).pipe(
      finalize(() => this.setLoading(false)),
    );
  }

  addPlatform(projectId: number, payload: ProjectPlatformCreateRequest) {
    this.setLoading(true);
    return this.http.post<ProjectPlatform>(`${environment.apiUrl}/projects/${projectId}/add-platform`, payload)
      .pipe(finalize(() => this.setLoading(false)));
  }

  deletePlatforms(projectId: number, platformIds: number[]) {
    this.setLoading(true);
    return this.http.request('delete', `${environment.apiUrl}/projects/${projectId}/remove-platform`, { body: { platformIds } })
      .pipe(finalize(() => this.setLoading(false)));
  }

  cloneProject(payload: ProjectCloneRequest) {
    return this.http.post<Project>(`${environment.apiUrl}/projects/clone`, payload);
  }

  generateProjectName(projectName: string) {
    return this.http
      .get<any>(
        `${environment.apiUrl}/projects/generate-name-project?projectName=${projectName}`
      )
      .pipe(map((response) => response.key));
  }

  importJira(file: FormData): Observable<any> {
    return this.http.post(`${environment.apiUrl}/projects/import`, file, {
      reportProgress: true,
      observe: 'events',
      headers: {
        'ngsw-bypass': 'true',
      }
    });
  }

  restoreProject(id: number) {
    return this.http.patch<Project>(`${environment.apiUrl}/projects/${id}/restore-projects`, {});
  }
}
