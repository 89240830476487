import { TemplateRef } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

export class SearchOverlayConfig<T = any> {
  title: string;
  items: T[] = [];
  selecteds: T[] = [];
  multiple: boolean;
  loading: boolean;
  searchable: boolean;
  bindId: string;
  bindLabel: string;
  selectedTemplate: TemplateRef<any>;
  optionTemplate: TemplateRef<any>;
  footerTemplate: TemplateRef<any>;
  searchTypeahead$: Subject<any>;
  triggerItems$: Subject<T[]>;
  searchSubscription: Subscription;
  search: (keyword: string) => any;

  constructor(data?: SearchOverlayConfig<T>) {
    this.title = data?.title || '';
    this.items = data?.items || [];
    this.selecteds = data?.items || [];
    this.multiple = data?.multiple || false;
    this.loading = data?.loading || false;
    this.searchable = data?.searchable || true;
    this.bindId = data?.title || 'id';
    this.bindLabel = data?.title || '';
    this.selectedTemplate = data?.selectedTemplate || null;
    this.optionTemplate = data?.optionTemplate || null;
    this.footerTemplate = data?.footerTemplate || null;
    this.searchTypeahead$ = data?.searchTypeahead$ || new Subject<string>();
    this.triggerItems$ = data?.triggerItems$ || new Subject<T[]>();
    this.search = data?.search || null;
  }
}
