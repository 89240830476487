<a
  class="breadcumb-item"
  *ngIf="activePath && !label"
  [href]="getNavigateLink | function : activePath : replaceKeyValue"
  stopDefaultEvent
  dropdown
  #dropdown="bs-dropdown"
  container="body"
  [insideClick]="true"
  placement="bottom left"
>
  <div class="breadcumb-item-content" dropdownToggle>
    <span class="btn-arrow-right"></span>

    <span
      class="breadcumb-label"
      [ngClass]="{ 'last-label': isLastInBreadcumb }"
    >
      <span><app-i18n [key]="selectedOption?.text"></app-i18n></span>
    </span>

    <div
      class="dropdown-menu custom-search-dropdown-menu breadcumb-item-dropdown-menu"
      *dropdownMenu
    >
      <div class="option-list">
        <ng-container *ngFor="let option of options">
          <div
            class="custom-option"
            [ngClass]="{ selected: option.routerLink === activePath }"
            (click)="dropdown.isOpen = false; navigateTo(option.routerLink)"
          >
            <a
              [routerLink]="
                getNavigateLink | function : option.routerLink : replaceKeyValue
              "
              (click)="$event.stopPropagation()"
            >
              <span class="option-label"
                ><app-i18n [key]="option?.text"></app-i18n
              ></span>
            </a>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</a>

<a
  class="breadcumb-item label-item"
  *ngIf="!activePath && label"
  [routerLink]="getNavigateLink | function : activePath : replaceKeyValue"
>
  <div class="breadcumb-item-content">
    <span class="btn-arrow-right"></span>
    <span
      class="breadcumb-label"
      [ngClass]="{ 'last-label': isLastInBreadcumb }"
    >
      <span><app-i18n [key]="label"></app-i18n></span>
    </span>
  </div>
</a>
