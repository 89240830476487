import { Component, OnInit } from '@angular/core';
import * as queryString from 'query-string';
import { debounceTime, startWith } from 'rxjs/operators';
import { OPTIONS_PER_PAGE } from 'src/app/shared/_utils';
import { TeamService } from 'src/app/site-management/team/_services/team.service';
import { SearchControlComponent } from '../base/search-base.component';
import { ITeam } from './search-team';

@Component({
  selector: 'app-search-team',
  templateUrl: './search-team.component.html',
  styleUrls: ['./search-team.component.scss']
})
export class SearchTeamComponent extends SearchControlComponent<ITeam> implements OnInit {
  constructor(private teamService: TeamService) {
    super();
  }

  ngOnInit(): void {
    this.typing$.pipe(startWith(''), debounceTime(500)).subscribe((res) => {
      this.searchDto.keyword = res;
      this.fetch();
    });
  }

  fetch(): void {
    const query = queryString.stringify({
      ...this.searchDto,
      size: OPTIONS_PER_PAGE
    });

    this.sub?.unsubscribe();
    this.sub = this.teamService.getWithQuery(query).subscribe((res) => {
      this.items = res;
    });
  }
}
