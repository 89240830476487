import { OkrType } from 'src/app/site-management/okr/_model';
import { OkrCategory, OkrIndicator, OkrResultType, OkrRiskLevel } from '../_utils';
import { AttachmentType, BaseAttachment } from './attachment.model';
import { Group } from './group.model';
import { Metrics } from './metrics.model';
import { OkrTimeline } from './okr-timeline.model';
import { Okr, OkrBase, OkrTask } from './okr.model';
import { Team } from './team.model';
import { User } from './user.model';

export interface OkrKeyResult extends OkrBase {
  id: number;
  key: string;
  name: string;
  description: string;
  dueDate: string;
  resultType: OkrResultType;
  start: number;
  current: number;
  expected: number;
  weight: number;
  itemsWeight: number;
  progress: number;
  deleted: boolean;
  timeline: OkrTimeline;
  assignee: User;
  stakeHolder: User;
  createBy: User;
  okr: Okr;
  okrTeams: Team[];
  okrGroups: Group[];
  items: OkrTask[];
  metric: Metrics;
  riskLevel: OkrRiskLevel;
  percentValue: number;
  totalValue: number;
  attachments: OkrKeyResultAttachment[];
  type: OkrType;
  orderNumber?: number;
  indicator?: OkrIndicator;
  category?: OkrCategory;
  totalCheckin?: number;
  linkedKeyResult?: OkrKeyResult;
  rootTimelineId?: number;
  percentFx?: string;
  riskFx?: string;
}

export interface OkrKeyResultItemRequest {
  current: number;
  weight: number;
  taskId: number;
  assigneeId: number;
}

export interface OkrKeyResultItemCreateRequest {
  weight: number;
  taskId: number;
  assigneeId: number;
}

export interface OkrKeyResultCreateRequest {
  name: string;
  description: string;
  assigneeId: number;
  stakeholderId: number;
  okrId: number;
  teamIds: number[];
  groupIds: number[];
  timelineId: number;
  dueDate: string;
  resultType: OkrResultType;
  start: number;
  current: number;
  expected: number;
  weight: number;
  itemsWeight: number;
  items: OkrKeyResultItemRequest[];
  metricId: number;
  justify: number;
  type: OkrType;
  indicator?: OkrIndicator;
  category?: OkrCategory;
  percentFx?: string;
  riskFx?: string;
}

export interface OkrKeyResultUpdateRequest {
  name: string;
  description: string;
  assigneeId: number;
  stakeholderId: number;
  teamIds: number[];
  groupIds: number[];
  timelineId: number;
  dueDate: string;
  resultType: OkrResultType;
  start: number;
  current: number;
  expected: number;
  weight: number;
  itemsWeight: number;
  justify: number;
  type: OkrType;
  note?: string;
  indicator?: OkrIndicator;
  category?: OkrCategory;
  resetCheckin?: boolean;
  percentFx?: string;
  riskFx?: string;
}

export interface OkrKeyResultItemUpdateRequest {
  weight: number;
  assigneeId: number;
}

export class OkrKeyResultAttachment extends BaseAttachment {

}

export interface KrUpsertFile {
  file?: File;
  type?: AttachmentType;
  id?: number;
  typeOfObject?: string;
  previewUrl?: string;
}

export enum KrPatchField {
  DESCRIPTION = "description"
}

export interface KrPatch {
  [KrPatchField.DESCRIPTION]?: string;
}
