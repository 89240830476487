import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectState } from './project.models';

export const featureKey = 'project';

const selectFeature = createFeatureSelector<ProjectState>(featureKey);

export const selectProjects = createSelector(
  selectFeature,
  (state: ProjectState) => state.projects
);

export const selectProjectMembers = createSelector(
  selectFeature,
  (state: ProjectState) => state.members
);

export const selectStatuses = createSelector(
  selectFeature,
  (state: ProjectState) => state.statuses
);

export const selectPriorities = createSelector(
  selectFeature,
  (state: ProjectState) => state.priorities
);

export const selectReleases = createSelector(
  selectFeature,
  (state: ProjectState) => state.releases
);

export const selectEpics = createSelector(
  selectFeature,
  (state: ProjectState) => state.epics
);

export const selectSprints = createSelector(
  selectFeature,
  (state: ProjectState) => state.sprints
);

export const selectLabels = createSelector(
  selectFeature,
  (state: ProjectState) => state.labels
);

export const selectStoryLines = createSelector(
  selectFeature,
  (state: ProjectState) => state.storyLines
);

export const selectPlatforms = createSelector(
  selectFeature,
  (state: ProjectState) => state.platforms
);

export const selectMembersByProject = createSelector(
  selectFeature,
  (state: ProjectState) => state.memberByProject
);

export const selectMembersInProject = (projectId: number) =>
  createSelector(
    selectMembersByProject,
    (items) => items.entities[projectId]
  );

export const ProjectSelectors = {
  selectProjects,
  selectProjectMembers,
  selectStatuses,
  selectPriorities,
  selectReleases,
  selectEpics,
  selectSprints,
  selectLabels,
  selectStoryLines,
  selectPlatforms,
  selectMembersByProject,
  selectMembersInProject
};
