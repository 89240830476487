import { createAction, props } from '@ngrx/store';
import {
  BoardEpic, BoardEpicSearchRequest, BoardLabel, BoardLabelSearchRequest, BoardReleaseListResponse,
  BoardReleaseSearchRequest, BoardSprint, BoardSprintSearchRequest, BoardStatus,
  Pageable, PageableModel, Project, ProjectMemberUser, ProjectPlatform, ProjectPriority, StatusSearchParams
} from 'src/app/shared';
import { BoardStoryline, BoardStorylineSearchByAvailablePlatforms } from 'src/app/shared/_models/board-storyline.model';
import { MemberList } from './project.models';

export const resetProjectState = createAction(
  '[Project] Reset Project State'
);

export const getAllProjects = createAction(
  '[Project] Get All Projects',
  props<{ force?: boolean }>()
);

export const getProjectAllMembers = createAction(
  '[Project] Get Project All Members',
  props<{ projectId: number; keyword?: string; force?: boolean }>()
);

export const setProjects = createAction(
  '[Project] Set All Projects',
  props<{ projects: Project[] }>()
);

export const setProjectMembers = createAction(
  '[Project] Set Project All Members',
  props<{ members: ProjectMemberUser[] }>()
);

export const getStatuses = createAction(
  '[Project] Get All Status',
  props<{ projectId: number; params: StatusSearchParams; force?: boolean }>()
);

export const setStatuses = createAction(
  '[Project] Set All Status',
  props<{ statuses: BoardStatus[]}>()
);

export const getPriorities = createAction(
  '[Project] Get All Priority',
  props<{ projectId: number; params: PageableModel; force?: boolean }>()
);

export const setPriorities = createAction(
  '[Project] Set All Priority',
  props<{ priorities: ProjectPriority[] }>()
);

export const getReleases = createAction(
  '[Project] Get All Releases',
  props<{ payload: BoardReleaseSearchRequest; pageable: Pageable; force?: boolean }>()
);

export const setReleases = createAction(
  '[Project] Set All Releases',
  props<{ releases: BoardReleaseListResponse[] }>()
);

export const getEpics = createAction(
  '[Project] Get All Epics',
  props<{ payload: BoardEpicSearchRequest; pageable: Pageable; force?: boolean }>()
);

export const setEpics = createAction(
  '[Project] Set All Epics',
  props<{ epics: BoardEpic[] }>()
);

export const getSprints = createAction(
  '[Project] Get All Sprints',
  props<{ payload: BoardSprintSearchRequest; pageable: Pageable; force?: boolean }>()
);

export const setSprints = createAction(
  '[Project] Set All Sprints',
  props<{ sprints: BoardSprint[] }>()
);

export const getLabels = createAction(
  '[Project] Get All Labels',
  props<{ payload: BoardLabelSearchRequest; pageable: Pageable; force?: boolean }>()
);

export const setLabels = createAction(
  '[Project] Set All Labels',
  props<{ labels: BoardLabel[] }>()
);

export const getStorylines = createAction(
  '[Project] Get All Storylines',
  props<{ projectId: number; payload?: BoardStorylineSearchByAvailablePlatforms; pageable: Pageable, force?: boolean }>()
);

export const setStorylines = createAction(
  '[Project] Set All Storylines',
  props<{ storyLines: BoardStoryline[] }>()
);

export const getPlatforms = createAction(
  '[Project] Get All Platforms',
  props<{ projectId: number, force?: boolean }>()
);

export const setPlatforms = createAction(
  '[Project] Set All Platforms',
  props<{ platforms: ProjectPlatform[] }>()
);

export const getMembersByProject = createAction(
  '[Project] Get Members By Project',
  props<{
    projectId: number;
    keyword?: string;
    force?: boolean;
    callback?: (members: ProjectMemberUser[]) => void;
  }>()
);

export const setMembersByProject = createAction(
  '[Project] Set Members By Project',
  props<{ item: MemberList }>()
);

export const ProjectActions = {
  resetProjectState,
  getAllProjects,
  getProjectAllMembers,
  setProjects,
  setProjectMembers,
  getStatuses,
  setStatuses,
  getPriorities,
  setPriorities,
  getReleases,
  setReleases,
  getEpics,
  setEpics,
  getSprints,
  setSprints,
  getLabels,
  setLabels,
  getStorylines,
  setStorylines,
  getPlatforms,
  setPlatforms,
  getMembersByProject,
  setMembersByProject,
};
