import { forEach } from 'lodash';
import {
  Okr,
  OkrItem,
  OkrKeyResult,
  OkrTask,
  VirtualOkr,
  VirtualOkrItem,
  VirtualOkrPath
} from 'src/app/shared';
import { v4 } from 'uuid';
import { OKR_ROW_FIRST_LEVEL, OkrFormType } from './okr.model';

export const getOkrType = (item: OkrItem): OkrFormType => {
  if (item.objectiveType) {
    return OkrFormType.Objective;
  }

  if (item.type) {
    return OkrFormType.KeyResult;
  }

  return OkrFormType.Task;
};

export const getOkrPath = (item: VirtualOkrItem): VirtualOkrPath[] => {
  if (item) {
    return [{ id: item.id, key: item.key }, ...getOkrPath(item._parent)];
  }
  return [];
};

export const commonProps = (
  formType: OkrFormType,
  lvl: number,
  sub: VirtualOkrItem[],
  visible = false,
  parent?: VirtualOkrItem
) => {
  return {
    _id: v4(),
    _state: 'initial',
    _subItems: sub || [],
    _lvl: lvl || OKR_ROW_FIRST_LEVEL,
    _formType: formType,
    _isExpanded: false,
    _visible: visible,
    _parent: parent,
    _path: getOkrPath(parent)
  } as VirtualOkr;
};

export const mapTask = (okr: OkrItem, parent: Okr, lvl?: number) => {
  // check if task has parent as KeyResult
  if (okr.keyResult) {
    const keyResult = parent._subItems?.find(
      (e) => e.id === okr.keyResult.id
    ) as OkrKeyResult;

    if (!keyResult) {
      return;
    }

    const task = mapModelOkrTask(okr, keyResult._lvl + 1, keyResult);
    keyResult._subItems.push(task);

    keyResult.items.push(task);
    return;
  }
  const task = mapModelOkrTask(okr, lvl, parent);

  // add Task to parent if parent is Objective with different model
  parent.tasks.push(task);
  parent._subItems.push(task);
};

export const mapKeyResult = (okr: OkrItem, parent: Okr, lvl?: number) => {
  const keyResult = parent.keyResults?.find((e) => e.id === okr.keyResult?.id);

  if (keyResult) {
    return [];
  }

  const currentItem = mapModelKeyResult(okr, parent, lvl);
  return [currentItem];
};

export const mapObjective = (
  okr: OkrItem,
  parent?: Okr,
  lvl?: number,
  visible = false
) => {
  const currentItem = mapModelObjective(okr, parent, lvl, visible);

  forEach(okr.children, (item) => {
    const type = getOkrType(item);

    if (type === OkrFormType.Objective) {
      const objective = mapObjective(item, currentItem, lvl + 1);
      currentItem.children.push(objective);
      currentItem._subItems.push(objective);
    }

    if (type === OkrFormType.KeyResult) {
      const keyResult = mapKeyResult(item, currentItem, lvl + 1);

      currentItem.keyResults = [
        ...(currentItem.keyResults || []),
        ...keyResult
      ];

      currentItem._subItems = [...currentItem._subItems, ...keyResult];
    }

    if (type === OkrFormType.Task) {
      mapTask(item, currentItem, lvl + 1);
    }
  });

  currentItem.children = currentItem.children.sort(
    (a, b) => a.orderNumber - b.orderNumber
  );
  return currentItem;
};

export const mapModelObjective = (
  objective: OkrItem,
  parent?: Okr,
  lvl?: number,
  visible = false
): Okr => {
  const okr = objective.linkedOkr || objective;

  return {
    ...commonProps(OkrFormType.Objective, lvl, [], visible, parent),
    id: objective.id,
    objectiveType: okr.objectiveType,
    name: okr.name,
    key: okr.key,
    description: okr.description || '-',
    dueDate: okr.dueDate,
    resultType: okr.resultType,
    start: okr.start,
    current: okr.current,
    expected: okr.expected,
    justify: okr.justify,
    weight: okr.weight,
    progress: okr.progress,
    timeline: okr.timeline,
    childTimelines: objective.childTimelines,
    countChildTimelines: objective.countChildTimelines,
    assignee: okr.assignee,
    stakeHolder: okr.stakeHolder,
    createBy: okr.createBy,
    okrTeams: okr.okrTeams,
    okrGroups: okr.okrGroups,
    krWeight: okr.krWeight,
    metric: objective.metric,
    riskLevel: okr.riskLevel,
    percentValue: okr.percentValue,
    totalValue: okr.totalValue,
    type: okr.type,
    orderNumber: objective.orderNumber,
    isPopulate: okr.isPopulate,
    isEditable: okr.isEditable,
    workspace: okr.timeline?.workspace,
    indicator: okr.indicator,
    category: okr.category,
    hasChild: null,
    attachments: [],
    linkedOkr: objective.linkedOkr ? { ...objective.linkedOkr } : null,
    // ref
    children: [],
    keyResults: [],
    tasks: [],
    path: [],
    //
    parent
  };
};

export const mapModelKeyResult = (
  kr: OkrItem,
  parent?: Okr,
  lvl = OKR_ROW_FIRST_LEVEL
) => {
  const okr = kr.linkedKeyResult || kr;

  return {
    ...commonProps(OkrFormType.KeyResult, lvl, [], false, parent),
    id: kr.id,
    formType: 'keyResult',
    lvl,
    key: okr.key,
    name: okr.name,
    description: okr.description,
    dueDate: okr.dueDate,
    resultType: okr.resultType,
    start: okr.start,
    current: okr.current,
    expected: okr.expected,
    weight: okr.weight,
    itemsWeight: okr.itemsWeight,
    progress: okr.progress,
    deleted: false,
    timeline: kr.timeline,
    assignee: okr.assignee,
    stakeHolder: okr.stakeHolder,
    createBy: okr.createBy,
    okrTeams: okr.okrTeams,
    okrGroups: okr.okrGroups,
    metric: kr.metric,
    riskLevel: okr.riskLevel,
    percentValue: okr.percentValue,
    totalValue: okr.totalValue,
    attachments: [],
    type: okr.type,
    orderNumber: kr.orderNumber,
    indicator: okr.indicator,
    category: okr.category,
    totalCheckin: null,
    objectiveType: okr.objectiveType,
    justify: okr.justify,
    isEditable: okr.isEditable,
    linkedKeyResult: kr.linkedKeyResult ? { ...kr.linkedKeyResult } : null,
    //
    okr: parent,
    items: []
  } as OkrKeyResult;
};

export const mapModelOkrTask = (
  okr: OkrItem,
  lvl?: number,
  parent?: VirtualOkrItem
) => {
  return {
    ...commonProps(OkrFormType.Task, lvl, [], false, parent),
    id: okr.id,
    formType: 'task',
    lvl,
    taskId: okr.taskId,
    assignee: okr.assignee,
    current: okr.progress,
    percentValue: okr.percentValue,
    description: okr.description,
    key: okr.key,
    name: okr.name,
    weight: okr.weight,
    typeIcon: okr.typeIcon,
    metricValue: okr.expected,
    metric: okr.metric,
    orderNumber: okr.orderNumber,
    startMetricValue: okr.start,
    currentMetricValue: okr.current,
    dueDate: okr.dueDate,
    timelineId: okr.timeline?.id,
    isEditable: okr.isEditable,
    projectId: okr.projectId
  } as OkrTask;
};

export const mapOriginItemToVirtualOkr = ({
  item,
  parent,
  lvl,
  formType,
  subItems,
  visible
}: {
  item: VirtualOkrItem;
  parent: VirtualOkrItem;
  lvl: number;
  formType: OkrFormType;
  subItems: VirtualOkrItem[];
  visible: boolean;
}): VirtualOkrItem => {
  return {
    ...commonProps(formType, lvl, subItems, visible, parent),
    ...item
  };
};

export const mapOkrTaskToVirtualTask = (
  task: OkrTask,
  virtual: VirtualOkrItem,
) => {
  return {
    ...virtual,
    ...task,
  } as OkrTask;
};

export const mapObjectiveToVirtualObjective = (
  objective: Okr,
  virtual: VirtualOkrItem,
) => {
  return {
    ...virtual,
    ...objective,
  } as Okr;
};

export const mapKeyResultToVirtualKeyResult = (
  kr: OkrKeyResult,
  virtual: VirtualOkrItem,
) => {
  return {
    ...virtual,
    ...kr,
  } as OkrKeyResult;
};
